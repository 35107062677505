<template>
  <div class="bg-white h-screen overflow-hidden">
    <!-- Header -->
    <header class="absolute inset-x-0 top-0 z-50">
      <nav
        class="flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div class="flex lg:flex-1">
          <a href="#" class="-m-1.5 p-1.5 text-emerald-900 font-bold text-xl">
            <span class="sr-only">Big Leap Health</span>
            Big Leap Health
          </a>
        </div>
      </nav>
    </header>

    <main class="isolate">
      <!-- Hero section -->
      <div class="relative pt-14 md:pt-0">
        <div
          class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        ></div>
        <div class="py-24 sm:py-32">
          <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-2xl text-center">
              <h1
                class="text-4xl font-bold tracking-tight text-gray-900 sm:text-3xl"
              >
                {{ page.data.headline }}
              </h1>
              <prismic-rich-text
                :field="page.data.lead"
                class="mt-6 text-lg leading-8 text-gray-600"
              />
              <div class="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="#"
                  class="hidden rounded-md bg-emerald-900 px-3.5 py-2.5 text-md text-white shadow-sm hover:bg-emerald-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-900"
                  >{{ page.data.cta_join_mailinglist }}</a
                >
              </div>
              <div
                class="text-emerald-800 font-semibold"
                :class="{
                  hidden: !isSubmitted,
                }"
              >
                Thank you! We'll be in touch soon.
              </div>
              <div
                :class="{
                  hidden: isSubmitted,
                }"
              >
                <form-kit
                  type="form"
                  :value="form"
                  incomplete-message=" "
                  :submit-label="page.data.cta_join_mailinglist"
                  :classes="{
                    form: 'flex gap-2 justify-center',
                  }"
                  :submit-attrs="{
                    inputClass:
                      'rounded-md bg-emerald-900 px-5 md:px-10 py-2 text-sm text-white shadow-sm hover:bg-emerald-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-900',
                  }"
                  @submit="save"
                >
                  <div class="min-w-52 max-w-60">
                    <form-kit
                      validation-visibility="submit"
                      type="text"
                      name="email"
                      placeholder="email@domain.com"
                      validation="required|email"
                      :classes="{
                        input:
                          'block flex-1 border-0 bg-transparent py-1.5 pl-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6',
                        inner:
                          'flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md',
                      }"
                    />
                  </div>
                </form-kit>
              </div>
            </div>
            <div class="mt-16 flow-root sm:mt-24">
              <div
                class="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4"
              >
                <img
                  src="https://images.prismic.io/blh-coming-soon/Zqp9iR5LeNNTxrGU_bigleap-screenshot.webp?auto=format,compress"
                  alt="App screenshot"
                  width="2432"
                  height="1442"
                  class="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        ></div>
      </div>

      <!-- Logo cloud -->
    </main>
  </div>
</template>

<script setup>
const prismic = usePrismic();
const form = ref({ email: "" });
const isSubmitted = ref(false);

const { data: page } = useAsyncData("[home]", () =>
  prismic.client.getSingle("home"),
);
const {
  public: { apiBase, isProduction },
} = useRuntimeConfig();

const save = async (form) => {
  try {
    await $fetch(isProduction ? `${apiBase}/waitinglist` : `api/waitinglist`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: {
        email: form.email,
      },
    });
    isSubmitted.value = true;
  } catch (error) {}
};

useHead({
  title: page.value?.data.meta_title,
  meta: [
    {
      name: "description",
      content: page.value?.data.meta_description,
    },
  ],
});
</script>
